
export default {
    props: {
        externalURL: {
            type: String
        },
        btnText:{
            type: String,
            default: 'MOT Check'
        }
    },
    data: function () {
        return {
            recurringAmount: '7.99',
            regNumber: '',
            disableBtn: true,
            loading: false,
            isStationPlan: false,
            isFree: false,
            searchPlate: false,
        };
    },
    watch: {
        regNumber: function (value) {
            this.check_input(value);
        }
    },
    mounted: function () {
        this.searchPlate = false;
        var newUrl = window.location.href;
        var urlSearchParams = new URL(newUrl);
        var versionId = 'undefined'
        if (urlSearchParams.searchParams.get("id")) {
            versionId = urlSearchParams.searchParams.get("id");
        }
        sessionStorage.setItem('id', versionId);
        if (versionId === 'checkmotv4') {
            this.isStationPlan = true
            this.recurringAmount = '13.99'
        }
        else if (versionId === 'checkmotv6') {
            this.searchPlate = true;
        }
        else if (versionId === 'free-mot-check') {
            this.isStationPlan = false
            this.isFree = true
        }
        this.$emit("recurringAmount", this.recurringAmount);
        this.$emit("station", this.isStationPlan);

    },

    updated() {
        this.check_input(this.regNumber);
        this.$emit("recurringAmount", this.recurringAmount);
        this.$emit("station", this.isStationPlan);
    },

    methods: {
        // Function to handle Enter key press
    handleEnter() {
        console.log("here")
      if (this.regNumber.trim()) {
        this.submit(this.externalURL);
      }
    },
    // This function can also be used for the submit event on the form
    handleSubmit(event) {
      event.preventDefault(); // Prevent form default submission
      this.handleEnter(event); // Call handleEnter to handle logic
    },
        check_input: function (value) {
            if (value.length > 0) {
                this.regNumber = this.regNumber.replace(/ /g, '');
                this.regNumber.toUpperCase()
                this.disableBtn = false;
            } else this.disableBtn = true;
        },
        submit: function (externlURL) {
           let externalURL = externlURL ? externlURL : this.$config.journeyDomain+'/mot/search/'
           if(this.searchPlate)
            externalURL = this.$config.journeyDomain+'/mot/plate-search/'
            this.loading = true;
            this.disableBtn = true;
            var params = sessionStorage.getItem('id');
            if(externalURL == "https://checkmot.vaninsurance.co.uk/van-insurance/about-your-van"){
                window.location.assign(externalURL);
            }
            else if (this.isFree) {
                window.location.assign(externalURL + '?id=' + params + "&reg=" + this.regNumber.toUpperCase());
                this.loading = false;
            }
            else if (params && params !== 'undefined') {
                if (params === 'checkmotv5') {
                    window.location.assign(externalURL + '?id=' + params + "&reg=" + this.regNumber.toUpperCase());
                    this.loading = false;
                }
                else {
                    window.location.assign(externalURL + '?id=' + params + "&reg=" + this.regNumber.toUpperCase());
                    this.loading = false;
                }
            }
            else {
                window.location.assign(externalURL + '?id=checkmotv2' + "&reg=" + this.regNumber.toUpperCase());
                this.loading = false;
            }
        }

    }
}
